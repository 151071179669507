import React from 'react';

const HEADERS = [
  {
    date: 'Date',
    subject: 'Sujet',
    duration: 'Durée'
  }
];

const ROWS = [
  {
    date: '07/11/2024',
    subject:
      'Première séance : présentation du module, enjeux de la cyber, prise en main de mi-lxc',
    duration: '3h'
  },
  {
    date: '21/11/2024',
    subject: 'ARP spoofing',
    duration: '3h'
  },
  {
    date: '05/12/2024',
    subject: 'HTTPS, reverse proxy',
    duration: '4h'
  },
  {
    date: '19/12/2024',
    subject: 'Firewall Linux (iptables)',
    duration: '4h'
  },
  {
    date: '16/01/2025',
    subject: "pfSense : Mise en place d'un firewall",
    duration: '4h'
  },
  {
    date: '30/01/2025',
    subject: 'DNS',
    duration: '4h'
  },
  {
    date: '13/02/2025',
    subject: 'TP Forensic ?',
    duration: '4h'
  },
  {
    date: 'XX/XX/2025',
    subject: 'Evaluation ?',
    duration: '1h'
  }
];

export default function Planning() {
  return (
    <>
      <h1>
        Planning{' '}
        <span role="img" aria-label="Calendar">
          📅
        </span>
      </h1>

      <div className="beautify-table custom">
        <div className="beautify-table-head">
          <table>
            <thead>
              {HEADERS.map(({ date, subject, duration }, idx) => (
                <tr key={idx} className="head">
                  <th className="column1">{date}</th>
                  <th className="column2 fill">{subject}</th>
                  <th className="column3 fill">{duration}</th>
                </tr>
              ))}
            </thead>
          </table>
        </div>
        <div className="beautify-table-body">
          <table>
            <tbody>
              {ROWS.map(({ date, subject, duration }, idx) => (
                <tr key={idx} className="body">
                  <td className="column1">{date}</td>
                  <td className="column2 fill">{subject}</td>
                  <td className="column3 fill">{duration}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
