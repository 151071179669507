import React from 'react';
// import imgVeolia from '../../assets/img/veolia.png';
// import imgMel from '../../assets/img/mel.png';
// import reactImg from '../../assets/img/react.png';
// import nodeImg from '../../assets/img/node.png';
// import goImg from '../../assets/img/go-logo-white.svg';
// import archImg from '../../assets/img/archbtw.png';

export default function WhoAmI() {
  // const styles = {
  //   archImg: {}
  // };
  return (
    <>
      <section id="who-am-i" data-auto-animate>
        <h1>Présentation</h1>
      </section>

      <section id="who-am-i" data-auto-animate>
        <h1>Présentation</h1>
        <br />

        <h3>Nathan FOURRIER</h3>
        <br />

        <h4>Freelance en cybersécurité et développement </h4>

        {/* <img data-src={imgVeolia} alt="Veolia" /> */}
        {/* <img data-src={imgMel} alt="MEL" /> */}
      </section>
    </>
  );
}
